

function CustomReviews() {
    return(
        <div className="w-[80%] my-[100px]">
            
            
        </div>
    );
}

export default CustomReviews;